import { useCallback, useEffect, useState, MouseEvent } from "react";
import { MONOLITH_API_LINK, MONOLITH_LINK } from "@/lib/constants";
import { useRouter, useSearchParams } from "next/navigation";

type City = {
    id: number;
    name: string;
    state: string;
    slug: string;
};

export function useFormSearchCity() {
    const router = useRouter();
    const searchParams = useSearchParams();
    const [origin, setOrigin] = useState("");
    const [destination, setDestination] = useState("");
    const [searchOriginComplete, setSearchOriginComplete] = useState(false);
    const [searchDestinationComplete, setSearchDestinationComplete] = useState(false);
    const [originDate, setOriginDate] = useState(
        new Date().toLocaleDateString("en-CA")
    );
    const [destinationDate, setDestinationDate] = useState("");
    const [searchResultsOrigin, setSearchResultsOrigin] = useState<Array<City>>([]);
    const [searchResultsDestination, setSearchResultsDestination] = useState<Array<City>>([]);
    const [linkEnable, setLinkEnable] = useState(false);

    // Quando a página carrega, pega-se origem e destino salvos no localStorage
    useEffect(() => {
        const origin = localStorage.getItem("embarca-origem") || "";
        const destination = localStorage.getItem("embarca-destino") || "";
        if (origin) {
            setSearchOriginComplete(true);
            setOrigin(origin);
        }
        if (destination) {
            setSearchDestinationComplete(true);
            setDestination(destination);
        }
    }, []);

    // faz o evento do google só depois origem e destino estão preenchidos
    useEffect(() => {
        setLinkEnable(!!(origin && destination));
    }, [origin, destination]);

    const swapDestination = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const aux = origin;
        setOrigin(destination);
        setDestination(aux);
    };

    const setOriginAndClearSearchResults = (value: City) => {
        setOrigin(value.slug || "");
        localStorage.setItem("embarca-origem", value.slug || "");
        setSearchResultsOrigin([]);
        setSearchOriginComplete(true);
    };

    const setDestinationAndClearSearchResults = (value: City) => {
        setDestination(value.slug || "");
        localStorage.setItem("embarca-destino", value.slug || "");
        setSearchResultsDestination([]);
        setSearchDestinationComplete(true);
    };

    const checkAndRedirect = async (isGratutityChecked?: boolean) => {
        if (linkEnable) {
            window?.dataLayer?.push({
                event: 'search',
                travel: {
                    departingDepartureDate: originDate,
                    originBusStation: origin,
                    destinationBusStation: destination,
                    departingArrivalDate: originDate,
                }
            });

            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            let adjustedOriginDate = originDate;

            if (isMobile) {
                const originDateObj = new Date(originDate);
                originDateObj.setDate(originDateObj.getDate() + 1);
                adjustedOriginDate = originDateObj.toISOString().split('T')[0];
            }


            const mobileUrl = `myapp://passagem-de-onibus/${origin}/${destination}/${adjustedOriginDate}/false`;
            const desktopUrl = buildUrl((isGratutityChecked));

            if (isGratutityChecked) {
                router.push(buildUrl(isGratutityChecked));
            } else {
                router.push(buildUrl());
            }

            try {
                if (isMobile) {
                    await router.push(desktopUrl);
                    setTimeout(async () => {
                        await router.push(desktopUrl);
                    }, 800);
                } else {
                    await router.push(desktopUrl);
                }
            } catch (error) {
                await router.push(desktopUrl);
            }
        } else {
            console.log('Link is not enabled');
        }
    };

    const buildUrl = (isGratutityChecked?: boolean) => {
        let url = `${MONOLITH_LINK}/passagem-de-onibus/${origin}/${destination}?departure_at=${originDate}`;
        if (destinationDate) {
            url += `&return_at=${destinationDate}`;
        }
        if (isGratutityChecked || searchParams.get("gratuity")) {
            url += "&gratuity=true";
        }
        return url;
    };

    const fetchOrigin = useCallback(async () => {
        if (searchOriginComplete) return;

        const NUMBER_OF_CHARACTERS = 1;

        try {
            if (origin.length > NUMBER_OF_CHARACTERS) {
                const originResponse = await fetch(
                    `${MONOLITH_API_LINK}/locations?query_text=${origin}`
                );
                const originData = await originResponse.json();
                setSearchResultsOrigin(originData);
            }
        } catch (error) {
            console.log(error);
        }
    }, [origin, searchOriginComplete]);

    const fetchDestination = useCallback(async () => {
        if (searchDestinationComplete) return;

        const NUMBER_OF_CHARACTERS = 1;

        try {
            if (destination.length > NUMBER_OF_CHARACTERS) {
                const destinationResponse = await fetch(
                    `${MONOLITH_API_LINK}/locations?query_text=${destination}`
                );
                const destinationData = await destinationResponse.json();
                setSearchResultsDestination(destinationData);
            }
        } catch (error) {
            console.log(error);
        }
    }, [destination, searchDestinationComplete]);


    const handleSearchOrigin = (value: any | null) => {
        setOrigin(value);
        setSearchOriginComplete(false);
    };

    const handleSearchDestination = (value: any | null) => {
        setDestination(value);
        setSearchDestinationComplete(false);
    };

    useEffect(() => {
        fetchOrigin();
    }, [origin, fetchOrigin]);

    useEffect(() => {
        fetchDestination();
    }, [destination, fetchDestination]);


    return {
        origin,
        setOrigin,
        destination,
        setDestination,
        originDate,
        setOriginDate,
        destinationDate,
        setDestinationDate,
        searchResultsOrigin,
        setSearchResultsOrigin,
        searchResultsDestination,
        setSearchResultsDestination,
        linkEnable,
        setLinkEnable,
        swapDestination,
        setOriginAndClearSearchResults,
        setDestinationAndClearSearchResults,
        handleSearchOrigin,
        handleSearchDestination,
        checkAndRedirect,
    };
}
