interface City {
    id: number;
    name: string;
    state: string;
    slug: string;
}

interface CityListProps {
    options: any[];
    setFunction: (value: City) => void;
    mobile?: boolean;
}

export default function CityList(props: CityListProps) {
    const { options, setFunction, mobile } = props;

    return (
        <ul>
            {options.map((item: any, index: number) => (
                <li
                    onClick={() => setFunction(item!)}
                    key={index}
                    className={`${mobile ? "text-white" : ""} px-5 py-2 hover:cursor-pointer hover:bg-gray-100 hover:text-black`}
                >
                    {`${item!.name} ${!!item!.state ?  '- ' + item!.state : ''}`}
                </li>
            ))}
        </ul>
    );
}