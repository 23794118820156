"use client";
import {
  Alert,
  Carousel,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Breadcrumbs,
  Rating,
  Input,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
export {
  Alert,
  Carousel,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Breadcrumbs,
  Rating,
  Input,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Dialog,
  DialogHeader,
  DialogBody,
};
