import { ChangeEvent, useState, useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { Dialog, DialogBody, DialogHeader } from "@/components/MaterialTailwind/MTProvider";

interface CheckBoxGratuityProps {
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CheckBoxGratuityMobile({ isChecked, setIsChecked }: CheckBoxGratuityProps) {

    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const handleOpen = () => setOpen((cur) => !cur);
    const [open, setOpen] = useState(false);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setIsChecked(checked);
    };

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader className="justify-between border-b-2">
                    <p className="inline-flex items-center">
                        <svg className="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="14" height="14" rx="7" fill="#E6FA00" />
                            <path d="M7.03365 10.054C6.80565 10.054 6.61365 9.994 6.45765 9.874C6.30765 9.748 6.23265 9.562 6.23265 9.316V6.22C6.23265 5.974 6.30765 5.791 6.45765 5.671C6.61365 5.545 6.80565 5.482 7.03365 5.482C7.26165 5.482 7.45065 5.545 7.60065 5.671C7.75665 5.791 7.83465 5.974 7.83465 6.22V9.316C7.83465 9.562 7.75665 9.748 7.60065 9.874C7.45065 9.994 7.26165 10.054 7.03365 10.054ZM7.03365 4.852C6.76365 4.852 6.54765 4.783 6.38565 4.645C6.22965 4.501 6.15165 4.312 6.15165 4.078C6.15165 3.844 6.22965 3.658 6.38565 3.52C6.54765 3.382 6.76365 3.313 7.03365 3.313C7.29765 3.313 7.51065 3.382 7.67265 3.52C7.83465 3.658 7.91565 3.844 7.91565 4.078C7.91565 4.312 7.83465 4.501 7.67265 4.645C7.51665 4.783 7.30365 4.852 7.03365 4.852Z" fill="black" />
                        </svg>
                        Gratuidades</p>
                    <svg
                        onClick={handleOpen}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </DialogHeader>
                <DialogBody className="overflow-y-scroll h-96 p-6">
                    <p className="text-gray-4 text-xl font-bold uppercase">RESOLUÇÃO ANTT Nº 6.033</p>
                    <div className="p-2 text-sm">
                        <p>Para obtenção do bilhete de passagem, o beneficiário ou seu representante deverá apresentar, no momento da solicitação, documento de identificação oficial com foto, número do CPF e documento válido de comprovação da condição para o benefício.
                        </p>
                        <ul className="list-disc p-4">
                            <li>
                                <b className="font-bold">Pessoa com deficiência</b> comprovadamente carente deverá apresentar a <b className="font-bold">credencial de Passe Livre</b>.
                            </li>
                            <li>
                                <b className="font-bold">Pessoa jovem</b> de baixa renda deverá apresentar a <b className="font-bold">Identidade Jovem</b>.
                            </li>
                            <li>
                                <b className="font-bold">Pessoa idosa de baixa renda</b> deverá apresentar um dos seguintes documentos:
                            </li>
                            <li>
                                Carteira de Trabalho e Previdência Social com anotações atualizadas;
                            </li>
                            <li>
                                contracheque de pagamento ou documento expedido pelo empregador;
                            </li>
                            <li>
                                carnê de contribuição para o Instituto Nacional do Seguro Social (INSS);
                            </li>
                            <li>
                                extrato de pagamento de benefício ou declaração fornecida pelo INSS ou outro regime de previdência social público ou privado;
                            </li>
                            <li>
                                Carteira da Pessoa Idosa, versão digital emitida pela Secretaria Nacional de Assistência Social (SNAS); ou documento ou carteira emitida pelas secretarias de assistência social, ou congêneres, em nível estadual ou municipal.
                            </li>
                            <li>
                                No caso de negativa da concessão do benefício, inclusive para a viagem de retorno, a autorizatária deverá emitir, no ato, documento ao solicitante, indicando:
                                nome e número do CPNJ da autorizatária;
                            </li>
                            <li>
                                data, origem e destino da viagem pretendida;
                            </li>
                            <li>
                                data, hora e local da solicitação; e
                            </li>
                            <li>
                                motivo da recusa.
                            </li>
                        </ul>
                    </div>
                </DialogBody>
            </Dialog>
            <div className="bg-gray-4 mt-4 rounded-xl flex justify-between items-center px-8">
                <div className="flex">
                    <svg onClick={handleOpen} className="mr-6 my-2 cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" rx="12" fill="#E6FA00" />
                        <path d="M12.115 18.09C11.735 18.09 11.415 17.99 11.155 17.79C10.905 17.58 10.78 17.27 10.78 16.86V11.7C10.78 11.29 10.905 10.985 11.155 10.785C11.415 10.575 11.735 10.47 12.115 10.47C12.495 10.47 12.81 10.575 13.06 10.785C13.32 10.985 13.45 11.29 13.45 11.7V16.86C13.45 17.27 13.32 17.58 13.06 17.79C12.81 17.99 12.495 18.09 12.115 18.09ZM12.115 9.42C11.665 9.42 11.305 9.305 11.035 9.075C10.775 8.835 10.645 8.52 10.645 8.13C10.645 7.74 10.775 7.43 11.035 7.2C11.305 6.97 11.665 6.855 12.115 6.855C12.555 6.855 12.91 6.97 13.18 7.2C13.45 7.43 13.585 7.74 13.585 8.13C13.585 8.52 13.45 8.835 13.18 9.075C12.92 9.305 12.565 9.42 12.115 9.42Z" fill="black" />
                    </svg>
                    <div>
                        <label htmlFor="gratuidade" className="font-bold text-white self-center inline-flex text-[12px]">
                            Tenho direito à gratuidade
                        </label>
                        <label htmlFor="gratuidade" className="block text-xs font-bold text-white  text-[9px]">Passe Livre, Idoso ou ID Jovem</label>
                    </div>
                </div>
                <input checked={isChecked} onChange={handleCheckboxChange} className="p-5 accent-primary w-4 h-4 rounded" type="checkbox" name="gratuidade" id="gratuidade" />
            </div>
        </>
    )
}