"use client";
import Image from 'next/image';
import magnifyingGlassIcon from '@/app/assets/magnifying-glass-icon.svg';
import navigationSmallIcon from '@/app/assets/navigation-small-icon.svg';
import destinationSmallIcon from '@/app/assets/destination-small-icon.svg';
import calendarSmallIcon from '@/app/assets/calendar-small-icon.svg';
import CityList from './CityList';
import { useFormSearchCity } from "@/hooks/searchCity";
import { useRef, useState } from 'react';
import { Dialog, DialogBody, DialogHeader } from "@/components/MaterialTailwind/MTProvider";
import CheckBoxGratuity from '../CheckBoxGratuity';

type HeightOption = 'higher' | 'nohigh' | 'standardhigh';

interface FormSearchTicketDesktopProps {
  heightOption?: HeightOption;
}

const heightClasses: { [key in HeightOption]: string } = {
  higher: 'mt-[19rem]',
  nohigh: '',
  standardhigh: 'mt-[30.75rem]',
};

export default function FormSearchTicketDesktop({ heightOption = 'standardhigh' }: FormSearchTicketDesktopProps) {

  const heightClass = heightClasses[heightOption];

  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);

  //To handle gratuity modal
  const handleOpen = () => setOpen((cur) => !cur);
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const {
    origin,
    destination,
    originDate,
    destinationDate,
    searchResultsOrigin,
    searchResultsDestination,
    setOriginAndClearSearchResults,
    setDestinationAndClearSearchResults,
    handleSearchOrigin,
    handleSearchDestination,
    setOriginDate,
    setDestinationDate,
    swapDestination,
    checkAndRedirect
  } = useFormSearchCity();


  return (
      <form className={`flex mx-auto ${heightClass} rounded-lg h-24 z-10 w-full max-w-6xl`}>
        <div className="relative border-e bg-white pt-5 rounded-s-lg flex-auto max-w-72  w-[14.5rem]">
          <label htmlFor="origin" className="font-bold text-black pl-4">Saindo de:</label>
          <div className="flex pl-4">
            <Image src={navigationSmallIcon} alt="Ícone de navegação" width={16} height={16} aria-label="Ícone de navegação" className="mr-1" />
            <input type="text" aria-autocomplete="none" autoComplete="off" required minLength={2} id="origin" value={origin || ""} onChange={e => handleSearchOrigin(e.target.value)} className="block uppercase text-black font-light bg-white focus:outline-none" placeholder="Origem" />
          </div>

          <CheckBoxGratuity isChecked={isChecked} setIsChecked={setIsChecked} />

          {/* botão inverter origem e destino */}
          <button type="button" className="absolute right-[-1.125rem] bottom-7" onClick={e => swapDestination(e)}>
            <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18" cy="18" r="18" transform="rotate(-90 18 18)" fill="#E6FA00" />
              <path d="m12 18 1.41 1.41L11.83 21H27v2H11.83l1.58 1.59L12 26l-4-4 4-4ZM24 18l-1.41-1.41L24.17 15H9v-2h15.17l-1.58-1.59L24 10l4 4-4 4Z" fill="#2D2D2D" />
            </svg>
            <p className="sr-only">Inverter origem e destino</p>
          </button>
          {searchResultsOrigin.length > 0 ? <div className="absolute bg-white h-44 overflow-auto rounded-b-xl">
            <CityList options={searchResultsOrigin} setFunction={setOriginAndClearSearchResults} />
          </div> : null}
        </div>
        <div className="border-e bg-white pt-5 pl-4 flex-auto max-w-72 w-[14.5rem]">
          <label htmlFor="destination" className="font-bold text-black pl-4">Indo para:</label>
          <div className="flex pl-4">
            <Image src={destinationSmallIcon} alt="Ícone de destino" width={18} height={18} aria-label="Ícone de destino" className="mr-1 w-[18px] h-[18px]" />
            <input type="text" aria-autocomplete="none" autoComplete="off" required minLength={2} id="destination" value={destination || ""} onChange={e => handleSearchDestination(e.target.value)} className="block uppercase text-black font-light bg-white focus:outline-none" placeholder="Destino" />
          </div>
          {searchResultsDestination.length > 0 ? <div className="bg-white h-44 overflow-auto rounded-b-xl">
            <CityList options={searchResultsDestination} setFunction={setDestinationAndClearSearchResults} />
          </div> : null}
        </div>
        <div className="border-e bg-white pt-5 pl-4 flex-auto">
          <label htmlFor="origin_date" className="font-bold text-black pl-4">Ida <small className="text-xs text-gray-4">(opcional)</small></label>
          <div className="flex px-4">
            <Image src={calendarSmallIcon} alt="Ícone de calendário" width={18} height={18} className="mr-1 w-[18px] h-[18px]" />
            <input type="date" id="origin_date" ref={originInputRef} onClick={() => originInputRef.current?.showPicker()} min={new Date().toISOString().split("T")[0]} required value={originDate} onChange={e => { setOriginDate(e.target.value) }} className="block text-black font-light bg-white focus:outline-none" placeholder="Destino" />
          </div>
        </div>
        <div className="border-e bg-white pt-5 pl-4 flex-auto">
          <label htmlFor="destination_date" className="font-bold text-black pl-4">Volta <small className="text-xs text-gray-4">(opcional)</small></label>
          <div className="flex px-4">
            <Image src={calendarSmallIcon} alt="Ícone de calendário" width={18} height={18} className="mr-1 w-[18px] h-[18px]" />
            <input type="date" id="destination_date" ref={destinationInputRef} onClick={() => destinationInputRef.current?.showPicker()} min={new Date().toISOString().split("T")[0]} value={destinationDate} onChange={e => { setDestinationDate(e.target.value) }} className="block text-black font-light bg-white focus:outline-none" placeholder="Destino" />
          </div>
        </div>
        <button type="submit" onClick={() => checkAndRedirect(isChecked)}
          className="flex items-center justify-center flex-grow bg-primary py-3 px-14 rounded-e-lg text-2xl font-semibold">
          <Image className="mr-2" src={magnifyingGlassIcon} height={22} width={22} role="presentation" alt="" />
          <p>Buscar</p>
        </button>
      </form>
  )
}