import CityList from "./CityList";
import { useState } from "react";
import { useFormSearchCity } from "@/hooks/searchCity";
import CheckBoxGratuityMobile from '../CheckBoxGratuity/mobile';


export default function FormSearchCityMobile() {

  const [isChecked, setIsChecked] = useState(false);

  const {
    origin,
    destination,
    originDate,
    destinationDate,
    searchResultsOrigin,
    searchResultsDestination,
    setOriginAndClearSearchResults,
    setDestinationAndClearSearchResults,
    handleSearchOrigin,
    handleSearchDestination,
    setOriginDate,
    setDestinationDate,
    swapDestination,
    checkAndRedirect,
  } = useFormSearchCity();

  return (
    <div className="py-4 mx-auto max-w-2xl">
      <form>
        <div className="grid gap-2">
          <div className="relative mb-2">
            <input
              type="text"
              id="origin"
              required
              minLength={2}
              value={origin || ""}
              onChange={(e) => {
                handleSearchOrigin(e.target.value);
              }}
              className="block uppercase rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-0 bg-gray-4 border-0 focus:outline-none"
              placeholder="Origem..."
            />
            <label
              htmlFor="origin"
              className="absolute text-sm text-gray-1 scale-75 top-4 z-10 origin-[0] left-2.5 -translate-y-4"
            >
              Saindo de:
            </label>
            <button
              type="button"
              onClick={(e) => swapDestination(e)}
              className={`absolute right-1 top-6 z-10 text-gray-5 focus:ring-0 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM23.0413 18.625L24.9997 16.6667L19.4441 11.1111L13.8886 16.6667L15.8469 18.625L18.0552 16.4306L18.0552 37.5H20.833L20.833 16.4306L23.0413 18.625ZM26.958 31.375L24.9997 33.3334L30.5552 38.8889L36.1108 33.3334L34.1525 31.375L31.9441 33.5695V12.5H29.1663L29.1663 33.5695L26.958 31.375Z"
                  fill="#E6FA00"
                />
              </svg>
              <p className="sr-only">Inverter origem e destino</p>
            </button>
            {searchResultsOrigin.length > 0 ? (
              <div className="relative bg-gray-4 h-40 overflow-auto rounded-xl z-20">
                <CityList options={searchResultsOrigin} setFunction={setOriginAndClearSearchResults} mobile />
              </div>
            ) : null}
          </div>
          <div className="relative">
            <input
              type="text"
              id="destination"
              required
              minLength={2}
              value={destination || ""}
              onChange={(e) => {
                handleSearchDestination(e.target.value);
              }}
              className="block uppercase rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-0 bg-gray-4 focus:outline-none"
              placeholder="Destino..."
            />
            <label
              htmlFor="destination"
              className="absolute text-sm text-gray-1 scale-75 top-4 z-10 origin-[0] left-2.5 -translate-y-4"
            >
              Indo para:
            </label>
            {searchResultsDestination.length > 0 ? (
              <div className="relative bg-gray-4 h-40 overflow-auto rounded-xl z-20">
                <CityList options={searchResultsDestination} setFunction={setDestinationAndClearSearchResults} mobile />
              </div>
            ) : null}
          </div>
          <div className="flex gap-2">
            <div className="rounded-lg bg-gray-4 w-full">
              <label htmlFor="origin_date" className="text-gray-1 pl-4 text-xs">
                Data:
              </label>
              <div>
                <input
                  type="date"
                  id="origin_date"
                  required
                  value={originDate}
                  onChange={(e) => {
                    setOriginDate(e.target.value);
                  }}
                  min={new Date().toISOString().split("T")[0]}
                  className="pl-3 text-gray-1 bg-gray-4 font-light w-full focus:outline-none"
                />
              </div>
            </div>
            <div className="rounded-lg bg-gray-4 w-full">
              <label
                htmlFor="destination_date"
                className="text-gray-1 pl-4 text-xs"
              >
                Data da volta:
              </label>
              <div>
                <input
                  type="date"
                  id="destination_date"
                  value={destinationDate}
                  onChange={(e) => {
                    setDestinationDate(e.target.value);
                  }}
                  min={new Date().toISOString().split("T")[0]}
                  className="pl-3 text-gray-1 bg-gray-4 font-light w-full focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => checkAndRedirect(isChecked)}
          type="submit"
          className={`flex justify-center bg-primary w-full px-5 py-5 mt-4 sm:mt-6 text-sm font-medium text-center text-black rounded-lg focus:ring-4 focus:ring-gray-5`}
        >
          Buscar passagens
        </button>
      </form>
      <CheckBoxGratuityMobile isChecked={isChecked} setIsChecked={setIsChecked} />
    </div>
  );
}
